import React, { FunctionComponent, useEffect, useState } from 'react';
import { Typography, Box, Grid, Container } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { PageProps } from 'gatsby';
import { WindowLocation } from '../types/PageProps';
import Layout from '../components/layout';
import QueueAnim from 'rc-queue-anim';
import theme from '../theme';
import * as imageOne from '../images/IMG_2858.jpg';
import * as imageTwo from '../images/IMG_2670.jpg';

const secondary = theme.palette.secondary.main;

const About: FunctionComponent<PageProps> = ({ location }) => {
  const [locationState, setLocationState] = useState<WindowLocation<unknown> | undefined>(location);
  
  useEffect(() => {
    if (location) {
      setLocationState(locationState);
    };
  }, []);

  const [items] = useState({
    items: [{
      children: ' Conveniently located in Northwest Columbus, our salon has provided professional services to women and men for the past thirty-seven years. ',
      key: 1
    }, {
      children: 'Our staff are trained in all the latest hair trends including The Protein Keratin Smoothing Treatment, Balayage and Ombre. ',
      key: 2
    }, {
      children: 'Dolores & Company is also offering a variety of hair extension products!',
      key: 3
    }, {
      children: ' ',
      key: 4
    }, {
      children: 'We are the only salon in Central Ohio trained in the art of color concentration.',
      key: 5
    }, {
      children: 'This procedure allows us to infuse multiple colors into the hair for a natural effect without fading.',
      key: 6
    }, {
      children: 'We also offer a unique Japanese treatment that strengthens the hair and lasts up to three months.',
      key: 7
    }, {
      children: ' ',
      key: 8
    }, {
      children: 'Come visit our salon and see what your hair has been missing! ',
      key: 9
    }]
  });


  return (
    <Layout location={locationState}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dolores Hair Designers</title>
        <link rel="canonical" href="http://doloreshairdesigners.com/about" />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container justify="center" alignItems="center" spacing={5}>
          <Grid item xs={6}>
            <Box style={{paddingBottom: 25}}>
              <Typography style={{padding: 25, fontFamily: 'Raleway', color: secondary}} align="center" variant="h2" component="h1" gutterBottom>
                About Us
              </Typography>
              <QueueAnim type={['top', 'bottom']} interval={[100, 200]} delay={[0, 1000]}
                duration={[500, 2000]}
                ease={['easeOutBack', 'easeInOutCirc']} leaveReverse
              >
                {items.items.map((item) => <div style={{fontSize: 25}} key={item.key}>
                  {item.children}
                </div>)}
              </QueueAnim>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Grid container justify="center" alignItems="center" spacing={5}>
          <Grid item xs={6}>
            <img src={imageOne} alt="salon" style={{borderRadius: 10, marginTop: 25, width: '100%'}} />
          </Grid>
          <Grid item xs={6}>
            <img src={imageTwo} alt="salon" style={{borderRadius: 10, marginTop: 25, width: '100%'}} />
          </Grid>
        </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default About